.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}
.wrapper {
  display: table-cell;
  vertical-align: middle;
}

.arrow-steps .step-part {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #343434;
  cursor: default;
  margin: 3px 3px;
  padding: 5px 5px 5px 25px;
  min-width: 40px;
  float: left;
  position: relative;
  background-color: #d6d6d6;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  // height: 30px;
}


.arrow-steps .step-part:after,
.arrow-steps .step-part:before {
  content: ' ';
  position: absolute;
  top: 0px;
  right: -16px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 17px solid #d6d6d6;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step-part:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

.arrow-steps .step-part:first-child:before {
  border: none;
}

.arrow-steps .step-part:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
   padding: 5px 5px 5px 10px;
}

.arrow-steps .step-part span {
  position: relative;
}

.arrow-steps .step-part span:before {
  opacity: 0;
  content: ' ';
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step-part.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

// .arrow-steps .step-part.current {
//   color: #fff;
//   background-color: green;
// }

// .arrow-steps .step-part.current:after {
//   border-left: 17px solid green;
// }

// .arrow-steps .step-part.done {
//   color: #fff;
//   background-color:#00800099;
// }

// .arrow-steps .step-part.done:after {
//   border-left: 17px solid #00800099;
// }

.arrow-steps .step-part.current {
  color: #fff;
  background: #005fd4;
}

.arrow-steps .step-part.current:after {
  border-left: 16px solid #005fd4;
}

.arrow-steps .step-part.done {
  color: #fff;
  background-color:#005fd487;
}

.arrow-steps .step-part.done:after {
  border-left: 16px solid #005fd487;
}