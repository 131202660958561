/* Global CSS, you probably don't need that */

// .clearfix:after {
//   clear: both;
//   content: '';
//   display: block;
//   height: 0;
// }
// .wrapper {
//   display: table-cell;
//   vertical-align: middle;
// }

.arrow-steps .step {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: #343434;
  cursor: default;
  margin: 3px 3px;
  padding: 3px 5px 3px 20px;
  min-width: 40px;
  float: left;
  position: relative;
  background-color: #d6d6d6;
  user-select: none;
  transition: background-color 0.2s ease;
  line-height: 18px;
  // height: 30px;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: ' ';
  position: absolute;
  top: 0px;
  right: -13px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 14px solid #d6d6d6;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 14px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 3px 5px 3px 10px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: ' ';
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

// .arrow-steps .step.current {
//   color: #fff;
//   background-color: green;
// }

// .arrow-steps .step.current:after {
//   border-left: 17px solid green;
// }

// .arrow-steps .step.done {
//   color: #fff;
//   background-color:#00800099;
// }

// .arrow-steps .step.done:after {
//   border-left: 17px solid #00800099;
// }

.arrow-steps .step.current {
  color: #fff;
  background: #005fd4;
}

.arrow-steps .step.current:after {
  border-left: 14px solid #005fd4;
}

.arrow-steps .step.done {
  color: #fff;
  background-color: #005fd487;
}

.arrow-steps .step.done:after {
  border-left: 13px solid #005fd487;
}
