$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #7c7d96 !default;
$gray-600: #dedeea !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1c1f46 !default;

:root {
  --primary-color: #005fd4; // Default color
  --sidebar-color: #ffffff; // Default color
  --sidebar-lighten-color: #ecf3f9; // Default color
  --sidebar-font-color: #777777; // Default color
  --sidebar-font-darker-color: #000; // Default color
  --header-color: #ffffff; // Default color
  --bg-color: #ffffff; // Default color
  --header-font-color: #000; // Default color
  --participant-header-bg-color: #f8f8f8; // Default color
}


@import './invite.scss';
@import './admin.scss';
@import './order.scss';
@import './common.scss';
@import './creditcard.scss';
